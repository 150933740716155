import { Button } from '@/components/ui/button';
import { Dialog, DialogTrigger } from '@/components/ui/dialog';
import { CodingQuestionListItem } from '@server/question';
import { useEffect, useState } from 'react';
import { Link, useFetcher } from 'react-router-dom';
import { DataTable } from './DataTable';
import { columns } from './columns';
import {
  NewSessionDialogContent,
  NewSessionDialogContentProps,
} from '@/components/NewSessionDialogContent';
import { TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Tooltip } from '@radix-ui/react-tooltip';

export const QuestionsList = ({ questions }: { questions: CodingQuestionListItem[] }) => {
  const fetcher = useFetcher();
  const [disabled, setDisabled] = useState(true);
  const [userStuckTooltipVisible, setUserStuckTooltipVisible] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState<CodingQuestionListItem[]>([]);
  const [createdSessionId, setCreatedSessionId] = useState<string>('');

  const selectionChangeHandler = (selectedQuestions: CodingQuestionListItem[]) => {
    setDisabled(selectedQuestions.length === 0);
    setSelectedQuestions(selectedQuestions);
    setUserStuckTooltipVisible(false);
  };

  const submitHandler: NewSessionDialogContentProps['onSubmit'] = ({
    candidateAddress,
    duration,
    params,
  }) => {
    fetcher.submit(
      {
        questions: selectedQuestions.map((q) => q.id),
        candidateAddress,
        params,
        duration,
      },
      { method: 'post' },
    );
  };

  useEffect(() => {
    if (fetcher.data) {
      setCreatedSessionId(fetcher.data);
    }
  }, [fetcher.data]);

  useEffect(() => {
    setTimeout(() => {
      if (disabled) {
        setUserStuckTooltipVisible(true);
      }
    }, 4250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog onOpenChange={(isOpened) => isOpened === false && setCreatedSessionId('')}>
      <div className="space-y-8 w-full p-4">
        <div className="flex justify-between items-center">
          <div className="relative w-5/12">
            {/* <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search..."
              className="w-full rounded-lg bg-background pl-8 w-full"
            /> */}
          </div>
          <TooltipProvider>
            <div className="flex gap-4 items-center">
              <Button variant="outline" asChild>
                <Link to="new">New Question</Link>
              </Button>
              <Tooltip open={userStuckTooltipVisible}>
                <DialogTrigger asChild>
                  <TooltipTrigger asChild>
                    <Button disabled={disabled}>Start new session</Button>
                  </TooltipTrigger>
                </DialogTrigger>
                <TooltipContent side="bottom" className="w-36 opacity-75">
                  <p>Select questions to start new session</p>
                </TooltipContent>
              </Tooltip>
            </div>
          </TooltipProvider>
        </div>
        <div className="rounded-md border">
          <DataTable
            data={questions}
            columns={columns}
            onSelectionChange={selectionChangeHandler}
          />
        </div>
      </div>
      <NewSessionDialogContent
        sessionUrl={
          createdSessionId
            ? `${import.meta.env.VITE_SESSION_APP_URL}/live/${createdSessionId}`
            : undefined
        }
        onSubmit={submitHandler}
      />
    </Dialog>
  );
};
