import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export const NonAuthenticatedQuestions = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-1">
      <h3 className="text-2xl font-bold tracking-tight">Sign in to view questions</h3>
      <p className="text-sm text-muted-foreground">
        Start screening candidates by adding questions
      </p>
      <Button className="mt-4" asChild>
        <Link to="/auth/sign-in">New Question</Link>
      </Button>
    </div>
  );
};
