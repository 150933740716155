import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';

export const UsersPage = () => {
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Invite new users</CardTitle>
        </CardHeader>
        <CardContent className="flex gap-4 items-center">
          <form className="flex-1">
            <Input placeholder="Invite user by email" />
          </form>
          <Button>Invite</Button>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Active users</CardTitle>
          <CardDescription>Active members of your account.</CardDescription>
        </CardHeader>
        <CardContent></CardContent>
      </Card>
    </>
  );
};
