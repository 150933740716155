import { Drama } from 'lucide-react';
import { Link } from 'react-router-dom';

export const Title = () => {
  return (
    <Link to="/" className="flex items-center gap-2 font-semibold">
      <Drama className="h-6 w-6" />
      <span className="text-2xl">Codiqle</span>
    </Link>
  );
}