import { BillingPage } from './pages/Billing';
import { UsersPage } from './pages/Users';

export default {
  Users: {
    position: 0,
    path: 'users',
    label: 'Users',
    element: UsersPage,
  },
  Billing: {
    position: 1,
    path: 'billing',
    label: 'Billing',
    element: BillingPage,
  },
};
