import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { NonAuthenticatedQuestions } from './NonAuthenticated';
import { EmptyState } from './EmptyState';
import { QuestionsList } from './QuestionsList';
import { listQuestions } from '@/api/questions';
import { Button } from '@/components/ui/button';

export const QuestionsPage = () => {
  const { isAuthenticated } = useAuth0();
  const { questions } = useLoaderData() as { questions: Awaited<ReturnType<typeof listQuestions>> };

  return (
    <main className="flex flex-1 h-full flex-col gap-4 p-4 lg:gap-6 lg:p-6">
      <div className="flex">
        <h1 className="text-lg font-semibold md:text-2xl">Questions</h1>
      </div>
      <div className="flex flex-1 justify-center">
        {isAuthenticated ? (
          <React.Suspense
            fallback={
              <div className="flex flex-col gap-2 justify-center items-center">
                <p className="text-muted-foreground">
                  Please wait, we are looking for your questions...
                </p>
              </div>
            }
          >
            <Await
              resolve={questions}
              errorElement={
                <div className="flex flex-col gap-2 justify-center items-center">
                  <p className="text-muted-foreground">Hmm..., something didn't go as planned :(</p>
                  <Button onClick={() => window.location.reload()}>Let's try again</Button>
                </div>
              }
            >
              {(questions) =>
                questions.length ? <QuestionsList questions={questions} /> : <EmptyState />
              }
            </Await>
          </React.Suspense>
        ) : (
          <NonAuthenticatedQuestions />
        )}
      </div>
    </main>
  );
};
