import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { Link, useBlocker, useLoaderData, useSubmit } from 'react-router-dom';
import AdminStructure from '../../structure';
import { FullCodingQuestion } from '@server/question';

export const CreateEditQuestion = () => {
  const { question } = useLoaderData() as { question: FullCodingQuestion | null };
  const submit = useSubmit();
  const [title, setTitle] = useState(question?.title ?? '');
  const [description, setDescription] = useState(question?.description ?? '');
  const [code, setCode] = useState(question?.code ?? '');
  const [lang] = useState(question?.lang ?? 'javascript');

  const isEditMode = question !== null;

  const isDirty = () => {
    if (isEditMode) {
      return (
        title !== question?.title ||
        description !== question?.description ||
        code !== question?.code
      );
    } else {
      return title !== '' || description !== '';
    }
  };

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty() && currentLocation.pathname !== nextLocation.pathname,
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit(
      {
        title,
        description,
        code,
        lang,
      },
      { method: 'post' },
    );
  };

  return (
    <main className="flex flex-1 flex-col gap-4 p-4 lg:gap-6 lg:p-6">
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">
          {isEditMode ? 'Edit' : 'Create'} question
        </h1>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="title">Question Title</Label>
            <Input
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter the question title"
              required
            />
          </div>

          <div className="space-y-2">
            <div className="space-x-2">
              <Label htmlFor="description">Description</Label>
              <span className="text-xs text-gray-500">Markdown is supported</span>
            </div>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter the question description"
              rows={5}
              required
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="code">Code</Label>
            <Editor
              height="50vh"
              theme="vs-dark"
              defaultLanguage={lang}
              value={code || '// write optional code here, like method signatures, etc.'}
              onChange={(value) => setCode(value ?? '')}
              options={{ minimap: { enabled: false } }}
            />
          </div>
          <div className="flex justify-end space-x-4">
            <Link to={AdminStructure.Questions.path}>
              <Button type="button" variant="outline">
                Cancel
              </Button>
            </Link>
            <Button type="submit">{isEditMode ? 'Save' : 'Create'}</Button>
          </div>
        </form>
      </div>
      <AlertDialog open={blocker.state === 'blocked'}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              Your filled fields' values will be lost if you continue.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => blocker.reset && blocker.reset()}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              className="bg-orange-400 hover:bg-orange-500"
              onClick={() => blocker.proceed && blocker.proceed()}
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </main>
  );
};
