import { Auth0Provider } from '@auth0/auth0-react';
import authConfig from '@/consts/auth.const';

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Auth0Provider
      {...authConfig}
    >
      {children}
    </Auth0Provider>
  );
};
