import { Checkbox } from '@/components/ui/checkbox';
import { CodingQuestionListItem } from '@server/question';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

export const columns: ColumnDef<CodingQuestionListItem>[] = [
  {
    id: 'select',
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  },
  {
    accessorKey: 'title',
    header: 'Title',
    cell: ({ row }) => (
      <Link to={`${row.original.id}/edit`}>
        <span className="line-clamp-2 hover:underline">{row.original.title}</span>
      </Link>
    ),
  },
  {
    accessorKey: 'description',
    header: 'Description',
    cell: ({ row }) => <span className="line-clamp-2">{row.original.description}</span>,
  },
];
