import { CircleHelp, Copy } from 'lucide-react';
import { useCallback, useState } from 'react';
import { toast } from 'sonner';
import { Button } from './ui/button';
import { Card, CardContent } from './ui/card';
import { Checkbox } from './ui/checkbox';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './ui/dialog';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip';

export interface NewSessionDialogContentProps {
  sessionUrl?: string;
  onSubmit: ({
    candidateAddress,
    duration,
    params,
  }: {
    candidateAddress: string;
    duration: number;
    params: {
      sendInvitation: boolean;
      disablePaste: boolean;
      enableRecording: boolean;
    };
  }) => void;
}

export const NewSessionDialogContent = ({ sessionUrl, onSubmit }: NewSessionDialogContentProps) => {
  const [candidateAddress, setCandidateAddress] = useState<string>('');
  const [sendInvitation, setSendInvitation] = useState<boolean>(false);
  const [disablePaste, setDisablePaste] = useState<boolean>(true);
  const [duration, setDuration] = useState<number>(60);

  const submitHandler = () => {
    onSubmit({
      candidateAddress,
      duration,
      params: {
        sendInvitation,
        disablePaste,
        enableRecording: false,
      },
    });
  };

  const linkCopyHandler = useCallback(() => {
    if (sessionUrl) {
      navigator.clipboard.writeText(sessionUrl).then(
        () => {
          toast.info('Link copied to clipboard');
        },
        () => {
          toast.error('Failed to copy link to clipboard');
        },
      );
    }
  }, [sessionUrl]);

  const renderSendInvitations = () => (
    <div className="flex gap-2 items-center">
      <Checkbox
        id="send-invitation"
        checked={sendInvitation}
        onCheckedChange={(val) =>
          val === 'indeterminate' ? setSendInvitation(true) : setSendInvitation(val)
        }
      />
      <Label htmlFor="send-invitation">Send invitation to the candidate</Label>
      <Tooltip>
        <TooltipTrigger asChild>
          <CircleHelp className="h-4 w-4 text-gray-500" />
        </TooltipTrigger>
        <TooltipContent className='w-64 opacity-75'>
          Candidates will receive an email with a link to join the session.
        </TooltipContent>
      </Tooltip>
    </div>
  );

  const renderDisablePaste = () => (
    <div className="flex gap-2 items-center">
      <Checkbox
        id="disable-paste"
        checked={disablePaste}
        onCheckedChange={(val) =>
          val === 'indeterminate' ? setDisablePaste(true) : setDisablePaste(val)
        }
      />
      <Label htmlFor="disable-paste">Disable paste</Label>
    </div>
  );

  const renderEnableRecording = () => (
    <Tooltip delayDuration={150}>
      <TooltipTrigger asChild>
        <div className="flex gap-2 items-center">
          <Checkbox id="recording" disabled />
          <Label htmlFor="recording">Record session</Label>
        </div>
      </TooltipTrigger>
      <TooltipContent className='opacity-75' side="left" align="end" alignOffset={-6} sideOffset={8}>
        Coming soon, really!
      </TooltipContent>
    </Tooltip>
  );

  if (sessionUrl) {
    return (
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Session Link</DialogTitle>
          <DialogDescription>
            You can access the session using the link or send it to the candidate.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link" className="sr-only">
              Link
            </Label>
            <Input id="link" defaultValue={sessionUrl} readOnly />
          </div>
          <Button className="px-3" onClick={linkCopyHandler}>
            <span className="sr-only">Copy</span>
            <Copy className="h-4 w-4" />
          </Button>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    );
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>New Session</DialogTitle>
        <DialogDescription>
          Create a new screening session with the selected questions.
        </DialogDescription>
      </DialogHeader>
      <TooltipProvider>
        <Card>
          <CardContent className="space-y-4 pt-6">
            <div className="space-y-1">
              <Label htmlFor="emails">Candidate's email</Label>
              <Input
                id="emails"
                required
                placeholder="john@doe.com"
                value={candidateAddress}
                onChange={ev => setCandidateAddress(ev.target.value)}
              />
            </div>
            {renderSendInvitations()}
          </CardContent>
        </Card>
        <div className="space-y-1">
          <Label htmlFor="duration">Duration (in minutes)</Label>
          <Input
            id="duration"
            type="number"
            value={duration}
            onChange={(ev) => ev.target.value && setDuration(Number(ev.target.value))}
          />
        </div>
        {renderDisablePaste()}
        {renderEnableRecording()}
      </TooltipProvider>
      <DialogFooter>
        <Button
          disabled={candidateAddress.length === 0}
          onClick={submitHandler}
        >
          Create
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};
