import { ICreateQuestionDto } from '@server/create-question';
import { CodingQuestionListItem, FullCodingQuestion } from '@server/question';
import fetcher from './fetcher';

export const createQuestion = async (question: ICreateQuestionDto) => {
  return fetcher.post('/questions', question);
};

export const listQuestions = async (): Promise<CodingQuestionListItem[]> => {
  return fetcher.get('/questions').then((res) => res.data);
};

export const getQuestionById = async (id?: string): Promise<FullCodingQuestion> => {
  if (!id) {
    throw new Error('Question ID is required');
  }
  return fetcher.get(`/questions/${id}`).then((res) => res.data);
};

export const updateQuestionById = async (
  id: string,
  body: Partial<FullCodingQuestion>,
): Promise<FullCodingQuestion> => {
  if (!id) {
    throw new Error('Question ID is required');
  }
  return fetcher.patch(`/questions/${id}`, body).then((res) => res.data);
};
