export const SessionsPage = () => {
  return (
    <main className="flex flex-1 h-full flex-col gap-4 p-4 lg:gap-6 lg:p-6">
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl">Sessions</h1>
      </div>
      <div className="flex flex-1 items-center justify-center">
        <div className="flex flex-col items-center gap-1 text-center">
          <h3 className="text-2xl font-bold tracking-tight">You have no screening sessions yet</h3>
          <p className="text-sm text-muted-foreground">
            The screening sessions with candidates will appear here
          </p>
        </div>
      </div>
    </main>
  );
};
