import { createBrowserRouter, defer, Navigate, redirect } from 'react-router-dom';
import { toast } from 'sonner';
import {
  createQuestion,
  getQuestionById,
  listQuestions,
  updateQuestionById,
} from './api/questions';
import { AdminApp } from './apps/admin/AdminApp';
import { CreateEditQuestion } from './apps/admin/pages/questions/CreateEditQuestion';
import AdminStructure from './apps/admin/structure';
import { SettingsApp } from './apps/settings/SettingsApp';
import SettingsStructure from './apps/settings/structure';
import FullScreenError from './components/FullScreenError';
import { Protected } from './components/auth/Protected';
import { SignIn } from './components/auth/SignIn';
import { SignOut } from './components/auth/SignOut';
import { createSession } from './api/sessions';

const router = createBrowserRouter([
  {
    path: '/',
    element: <AdminApp />,
    errorElement: <FullScreenError />,
    children: [
      {
        index: true,
        element: <Navigate replace to={AdminStructure.Questions.path} />,
      },
      {
        path: AdminStructure.Questions.path,
        element: <AdminStructure.Questions.element />,
        loader: async () => {
          return defer({ questions: listQuestions() });
        },
        action: async ({ request }) => {
          const formData = await request.formData();
          const session = await createSession({
            questions: formData.getAll('questions') as string[],
            candidateAddress: formData.get('candidateAddress') as string,
            duration: Number(formData.get('duration')) || 0,
            params: {
              sendInvitation: (formData.get('sendInvitation') || false) as boolean,
              disablePaste: (formData.get('disablePaste') || true) as boolean,
              enableRecording: (formData.get('enableRecording') || false) as boolean,
            },
          });
          return session;
        },
      },
      {
        path: `${AdminStructure.Questions.path}/new`,
        element: <Protected component={CreateEditQuestion} />,
        loader: () => {
          return { question: null };
        },
        action: async ({ request }) => {
          const formData = await request.formData();
          try {
            await createQuestion({
              title: formData.get('title') as string,
              description: formData.get('description') as string,
              code: formData.get('code') as string,
              lang: formData.get('lang') as string,
            });
            toast.success('Question is created successfully');
          } catch (error) {
            toast.error('Failed to create the question', {
              description: (error as Error).message,
            });
            return { ok: false };
          }
          return redirect(AdminStructure.Questions.path);
        },
      },
      {
        path: `${AdminStructure.Questions.path}/:id/edit`,
        element: <Protected component={CreateEditQuestion} />,
        loader: async ({ params }) => {
          const question = await getQuestionById(params.id);
          return { question };
        },
        action: async ({ request, params }) => {
          const formData = await request.formData();
          try {
            await updateQuestionById(params.id as string, {
              title: formData.get('title') as string,
              description: formData.get('description') as string,
              code: formData.get('code') as string,
            });
            toast.success('Question is updated successfully');
          } catch (error) {
            toast.error('Failed to update the question', {
              description: (error as Error).message,
            });
            return { ok: false };
          }
          return redirect(AdminStructure.Questions.path);
        },
      },
      {
        path: AdminStructure.Sessions.path,
        element: <Protected component={AdminStructure.Sessions.element} />,
      },
    ],
  },
  {
    path: '/auth',
    children: [
      {
        index: true,
        element: <Navigate replace to="sign-in" />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
      },
      {
        path: 'sign-out',
        element: <SignOut />,
      },
    ],
  },
  {
    path: '/settings',
    element: <Protected component={SettingsApp} />,
    children: [
      {
        index: true,
        element: <Navigate replace to={SettingsStructure.Users.path} />,
      },
      {
        path: SettingsStructure.Users.path,
        element: <SettingsStructure.Users.element />,
      },
      {
        path: SettingsStructure.Billing.path,
        element: <SettingsStructure.Billing.element />,
      },
    ],
  },
]);

export default router;
